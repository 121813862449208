import React, { useMemo } from "react";
import { FiMail, FiMapPin, FiPhoneCall } from "react-icons/fi";
import { useContactInfo } from "../../../lib/hooks/useContactInfo";
import { Container } from "../../Shared/Container/Container";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import {
  ContactHeroItem,
  ContactHeroItemProps,
} from "./components/ContactHeroItem";

export interface ContactHeroProps {
  className?: string;
}

export const ContactHero = (props: ContactHeroProps) => {
  const { className = "" } = props;
  const { phone, email, address } = useContactInfo();

  const items = useMemo<ContactHeroItemProps[]>(
    () => [
      {
        icon: <FiPhoneCall />,
        href: `tel:${phone}`,
        label: phone,
      },

      {
        icon: <FiMail />,
        href: `mailto:${email}`,
        label: email,
      },
      {
        icon: <FiMapPin />,
        href: `https://goo.gl/maps/dkWLfF1vT3ARwsoP7`,
        label: address,
      },
    ],
    []
  );

  return (
    <section className={`${className} py-16 border-primary border-b-2`}>
      <Container>
        <SectionHeader
          title="TEŠÍM SA NA SPOLUPRÁCU"
          subtitle="Kontaktné informácie"
        />

        <div className="contact flex justify-between mt-14 flex-col lg:flex-row space-y-6 lg:space-y-0">
          {items.map((item) => (
            <ContactHeroItem key={item.href} {...item} />
          ))}
        </div>
      </Container>
    </section>
  );
};
