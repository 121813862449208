import React from "react";
import { ReactNode } from "react-markdown";

export interface ContactHeroItemProps {
  className?: string;
  href: string;
  label: string;
  icon: ReactNode;
}

export const ContactHeroItem = (props: ContactHeroItemProps) => {
  const { className = "", href, label, icon } = props;
  return (
    <div className={`${className} flex space-x-4 items-center text-xl xl:text-2xl`}>
      <div className="icon text-primary text-2xl xl:text-3xl">{icon}</div>
      <a href={href} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    </div>
  );
};
