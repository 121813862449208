import React from "react";
import { ContactHero } from "../components/Contact/ContactHero/ContactHero";
import { Container } from "../components/Shared/Container/Container";
import Layout from "../components/Shared/Layout/Layout";
import { MessageFormSection } from "../components/Shared/MessageFormSection/MessageFormSection";

export interface ContactPageProps {
  className?: string;
}

export const ContactPage = (props: ContactPageProps) => {
  const { className = "" } = props;
  return (
    <Layout className={`${className}`}>
      <ContactHero />
      <div className="form pt-16">
        <MessageFormSection />
      </div>
    </Layout>
  );
};

export default ContactPage;
